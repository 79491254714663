<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16" style="
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          ">
        <div class="form-header">
          <div @click="back" style="
                position: absolute;
                left: 20px;
                font-size: 14px;
                cursor: pointer;
              ">
            返回
          </div>
          <div>申请服务</div>
        </div>
        <div class="form-content">
          <a-row>
            <a-col :span="24" :lg="12" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-row type="flex" align="middle">
                <a-col :span="12">
                  <div class="form-title-font">您选择的服务</div>
                </a-col>
                <a-col :span="12" style="text-align: right">
                  <div style="
                        display: flex;
                        flex-direction: row-reverse;
                        line-height: 32px;
                      ">
                    <div style="font-size: 14px; color: #101010; font-weight: 400">
                      {{ form.serviceName }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24" :lg="12" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-row type="flex" align="middle">
                <a-col :span="8" :lg="8">
                  <div class="form-title-font">咨询部门</div>
                </a-col>
                <a-col :span="16" :lg="16" style="text-align: right; line-height: 32px">
                  <div style="font-size: 14px; color: #101010; font-weight: 400">
                    {{ form.deptName }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <!-- 姓名 -->
          <a-row>
            <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-row type="flex" align="middle">
                <a-col :span="8" :lg="8">
                  <div class="form-title-font">
                    {{
                      $route.query.serviceName == "公益申诉"
                      ? "线索提供人姓名"
                      : "姓名"
                    }}
                  </div>
                </a-col>
                <a-col :span="16" :lg="16" style="text-align: right">
                  <a-input v-if="$route.query.serviceName == '公益申诉'" v-model="providePeople" class="form-value-font"
                    style="border: none" placeholder="请填写姓名" />
                  <a-input v-if="$route.query.serviceName != '公益申诉'" v-model="form.userName" class="form-value-font"
                    style="border: none" placeholder="请填写姓名" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <div v-if="$route.query.serviceName == '法律援助'">
            <!-- 性别 -->
            <a-row>
              <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-row type="flex" align="middle">
                  <a-col :span="8" :lg="8">
                    <div class="form-title-font">联系电话</div>
                  </a-col>
                  <a-col :span="16" :lg="16" style="text-align: right">
                    <el-radio-group v-model="sex" @change="handleSex">
                      <el-radio label="男">男</el-radio>
                      <el-radio label="女">女</el-radio>
                    </el-radio-group>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <!-- 民族 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  民族
                  <!-- <span style="color: red">*</span> -->
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <el-cascader v-model="nationValue" :options="nationList" @change="handleNationValue"></el-cascader>
              </a-col>
            </a-row>
          </div>
          <!-- 联系电话 -->
          <a-row>
            <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-row type="flex" align="middle">
                <a-col :span="8" :lg="8">
                  <div class="form-title-font">联系电话</div>
                </a-col>
                <a-col :span="16" :lg="16" style="text-align: right">
                  <a-input v-model="form.phone" class="form-value-font" style="border: none" placeholder="请填写联系电话" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <!-- 身份证号 -->
          <a-row>
            <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-row type="flex" align="middle">
                <a-col :span="8" :lg="8">
                  <div class="form-title-font">身份证号</div>
                </a-col>
                <a-col :span="16" :lg="16" style="text-align: right">
                  <a-input v-model="form.infoCard" class="form-value-font" style="border: none" placeholder="请填写身份证号" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <div v-if="$route.query.serviceName == '公益申诉'">
            <!-- 案件类别 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  案件类别
                  <!-- <span style="color: red">*</span> -->
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <el-cascader v-model="category" :options="categoryDict" @change="handleCategory"></el-cascader>
              </a-col>
            </a-row>
            <!-- 损害领域 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  损害领域
                  <!-- <span style="color: red">*</span> -->
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <el-cascader v-model="shiyValue" :options="shlyDict" @change="handleShlyValue"></el-cascader>
              </a-col>
            </a-row>
          </div>
          <!-- 所属地区 -->
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="8" :lg="4">
              <div class="form-title-font">
                {{
                  $route.query.serviceName == "公益申诉"
                  ? "损害区域"
                  : "所属地区"
                }}
                <!-- <span style="color: red">*</span> -->
              </div>
            </a-col>
            <a-col :span="16" :lg="20" style="text-align: right">
              <el-cascader v-model="address" :options="items" :props="{ expandTrigger: 'hover' }" @change="handleChange"
                ref="deptList"></el-cascader>
            </a-col>
          </a-row>
          <!-- 乡镇 -->
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="8" :lg="4">
              <div class="form-title-font">街道/乡镇</div>
            </a-col>
            <a-col :span="16" :lg="20" style="text-align: right">
              <el-cascader v-model="street" :clearable="true" :options="streets"
                :props="{ expandTrigger: 'hover', checkStrictly: false }" @change="handleStreetChange"></el-cascader>
            </a-col>
          </a-row>
          <!-- 法律援助 代理人信息 -->
          <div v-if="$route.query.serviceName == '法律援助'">
            <a-row style="height: 15px; background: #eee; padding: 0 29px"
              :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'"></a-row>
            <!-- 代理人姓名 -->
            <a-row>
              <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-row type="flex" align="middle">
                  <a-col :span="8" :lg="8">
                    <div class="form-title-font">代理人姓名</div>
                  </a-col>
                  <a-col :span="16" :lg="16" style="text-align: right">
                    <a-input v-model="agentObj.agentsName" class="form-value-font" style="border: none"
                      placeholder="请输入代理人姓名" />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <!-- 身份证号 -->
            <a-row>
              <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-row type="flex" align="middle">
                  <a-col :span="8" :lg="8">
                    <div class="form-title-font">身份证号</div>
                  </a-col>
                  <a-col :span="16" :lg="16" style="text-align: right">
                    <a-input v-model="agentObj.agentsIdCard" class="form-value-font" style="border: none"
                      placeholder="请填写身份证号" />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <!-- 联系电话 -->
            <a-row>
              <a-col :span="24" :lg="24" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-row type="flex" align="middle">
                  <a-col :span="8" :lg="8">
                    <div class="form-title-font">联系电话</div>
                  </a-col>
                  <a-col :span="16" :lg="16" style="text-align: right">
                    <a-input v-model="agentObj.agentsMobile" class="form-value-font" style="border: none"
                      placeholder="请填写联系电话" />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <!-- 代理关系 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  代理关系
                  <!-- <span style="color: red">*</span> -->
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <el-cascader v-model="agentObj.agentsContact" :options="agentRelationship" @change="handleAgentValue">
                </el-cascader>
              </a-col>
            </a-row>
            <a-row style="height: 15px; background: #eee; padding: 0 29px"
              :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'"></a-row>
          </div>
          <!-- 法律咨询 -->
          <a-row v-if="$route.query.serviceName == '法律咨询'" :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="8" :lg="4">
              <div class="form-title-font">
                问题类型
                <!-- <span style="color: red">*</span> -->
              </div>
            </a-col>
            <a-col :span="16" :lg="20" style="text-align: right">
              <el-cascader v-model="legalValue" :options="problemDict" @change="handleLegalValue"></el-cascader>
            </a-col>
          </a-row>
          <!-- 情况描述 -->
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="24" :lg="24">
              <div class="form-title-font">
                {{
                  $route.query.serviceName == "公益申诉"
                  ? "反映线索简要描述"
                  : $route.query.serviceName == "法律咨询"
                    ? "需求描述"
                    : "情况描述"
                }}
              </div>
            </a-col>
            <a-col :span="24" :lg="24" style="margin-top: 16px">
              <a-textarea v-model="form.description" placeholder="请对您的诉求进行详细对描述,便于部门更详细对为您服务" :auto-size="{ minRows: 10 }"
                style="
                    border: none;
                    background-color: #f4f4f4;
                    padding: 10px 20px;
                  " />
            </a-col>
          </a-row>
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="24" :lg="24">
              <div class="form-title-font">上传图片/视频</div>
            </a-col>
            <a-col :span="24" :lg="24" style="margin-top: 16px">
              <!-- old -->
              <!-- <el-upload
                action="https://fileprod.skillhub.top/file/imageFileUpload/"
                list-type="picture-card"
                style="width: 100%"
                :multiple="true"
                :limit="6"
                accept=".jpg,.png,.jpeg,.mp4"
                :on-success="chargedMaterialOnSuccess"
                :on-remove="chargedMaterialOnRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
              <!-- new -->
              <el-upload action="https://fileprod.skillhub.top/file/batchFileUploadImgVideo" :limit="9"
                :accept="'image/*,video/*'" :before-upload="beforeuploadMainGraph" :on-success="handleSuccessMainGraph"
                :on-preview="handlePreviewMainGraph" :before-remove="beforeremoveMainGraph"
                :on-remove="handleRemoveMainGraph" list-type="picture-card" :file-list="fileList" :multiple="true">
                <i class="el-icon-plus spc1"></i>
              </el-upload>
              <!-- 图片放大时候用的 -->
              <el-dialog :visible.sync="dialogVisibleMainGraph" class="spc12">
                <img width="100%" :src="imageUrl" alt="" />
              </el-dialog>
              <!-- 視頻放大时候用的 -->
              <el-dialog :visible.sync="dialogVisibleMainGraphVideo" class="spc12">
                <video :src="videoPath" controls></video>
              </el-dialog>
            </a-col>
          </a-row>
          <!-- 同意公开 -->
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
            <a-col :span="8" :lg="4">
              <div class="form-title-font">
                同意公开
                <!-- <span style="color: red">*</span> -->
              </div>
            </a-col>
            <a-col :span="16" :lg="20" style="text-align: right">
              <el-switch v-model="openStatus" active-color="#13ce66" inactive-color="#ff4949" @change="isOpen">
              </el-switch>
            </a-col>
          </a-row>
          <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'" style="margin-top: 10px; border: none">
            <a-col :span="24" style="font-size: 14px; color: #0e9bd2; font-weight: 400">*
              我们收到您的留言后将会在48小时内进行回复，请注意接听电话或者短信信息</a-col>
            <a-col :span="24" style="
                  margin-top: 8px;
                  font-size: 14px;
                  color: #0e9bd2;
                  font-weight: 400;
                ">* 服务进度查询请下载守未联盟APP使用提交人手机号登录查询</a-col>
          </a-row>
          <a-row type="flex" justify="center" style="padding: 64px 0 50px 0">
            <a-col :span="18" :lg="10">
              <a-button type="primary" block size="large" style="
                    font-size: 14px;
                    background-color: #0e9bd2;
                    color: #ffffff;
                  " @click="submitForm()">
                <a-icon type="mail" />提交申请
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <!-- 服务须知 -->
    <!-- <el-dialog title="提示" :show-close="false" :visible.sync="dialogVisible" width="50%" :modal="true"
      :modal-append-to-body="true" :append-to-body="true">
      {{ tipText }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="dialogVisible = false" style="width: 320px">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { ColorPicker } from "element-ui";
import { mapState, mapMutations } from "vuex";
export default {
  created() {
    this.tipText = JSON.parse(localStorage.getItem("tipText"));
  },
  data() {
    return {
      form: {
        userName: "",
        infoCard: "", // 身份证号
        deptId: "",
        deptName: "",
        serviceId: "",
        serviceName: "",
        phone: "",
        description: "",
        chargedMaterial: [],
      },

      items: [],
      streets: [],
      address: "",
      street: "",
      cityCode: "",

      tipText: "",

      // 性别
      sex: "男",
      // 公益申诉外的所在地区数据
      provinces: "", // 省会
      city: "", // 城市
      county: "", // 区
      villagesAndTowns: "", // 街道
      village: "", // 村

      // 公益申诉的损害区域等字段数据
      providePeople: "", // 提供人姓名
      /**损害区域：省份*/
      damageProvinces: "",
      /**损害区域：城市*/
      damageCity: "",
      /**损害区域：区县*/
      damageCounty: "",
      /**损害区域：乡镇**/
      damageVillagesAndTowns: "",
      /**损害区域：村*/

      damageVillage: "",
      dialogVisible: true,
      dialog: false,

      // 民族
      nationDict: [
        "汉族",
        "壮族",
        "满族",
        "回族",
        "苗族",
        "维吾尔族",
        "土家族",
        "彝族",
        "蒙古族",
        "藏族",
        "布依族",
        "侗族",
        "瑶族",
        "朝鲜族",
        "白族",
        "哈尼族",
        "哈萨克族",
        "黎族",
        "傣族",
        "畲族",
        "傈僳族",
        "仡佬族",
        "东乡族",
        "高山族",
        "拉祜族",
        "水族",
        "佤族",
        "纳西族",
        "羌族",
        "土族",
        "仫佬族",
        "锡伯族",
        "柯尔克孜族",
        "达斡尔族",
        "景颇族",
        "毛南族",
        "撒拉族",
        "布朗族",
        "塔吉克族",
        "阿昌族",
        "普米族",
        "鄂温克族",
        "怒族",
        "京族",
        "基诺族",
        "德昂族",
        "保安族",
        "俄罗斯族",
        "裕固族",
        "乌孜别克族",
        "门巴族",
        "鄂伦春族",
        "独龙族",
        "塔塔尔族",
        "赫哲族",
        "珞巴族",
        "穿青人",
        "其他",
      ],
      // 公益诉讼数据
      category: "",
      categoryDict: [
        { value: "行政公益诉讼", label: "行政公益诉讼" },
        { value: "民事公益诉讼", label: "民事公益诉讼" },
      ], // 案件类别
      // 损害领域
      shiyValue: "",
      shlyDict: [
        { value: "食品安全", label: "食品安全" },
        { value: "药品安全", label: "药品安全" },
        { value: "英烈保护", label: "英烈保护" },
        { value: "国有财产保护", label: "国有财产保护" },
        { value: "生态环境和资源保护", label: "生态环境和资源保护" },
        { value: "国有土地使用权出让", label: "国有土地使用权出让" },
        { value: "其他", label: "其他" },
      ],

      // 法律援助数据
      legalValue: "",
      problemDict: [
        { value: "刑事", label: "刑事" },
        { value: "民事", label: "民事" },
        { value: "行政", label: "行政" },
        { value: "其他", label: "其他" },
      ],

      // 上传图片/视频
      fileList: [],
      dialogVisibleMainGraph: false,
      dialogVisibleMainGraphVideo: false,
      imageUrlList: [],
      imageUrl: "",
      videoPath: "",

      // 民族
      nationValue: "",
      nationList: [],

      // 代理人信息
      agentObj: {
        /**代理人姓名*/
        agentsName: "",
        /**代理人身份证号*/
        agentsIdCard: "",
        /**代理人电话*/
        agentsMobile: "",
        /**代理关系*/
        agentsContact: "",
      },
      agentRelationship: [
        { value: "法定代理人", label: "法定代理人" },
        { value: "委托代理人", label: "委托代理人" },
      ],

      // 同意公开
      openStatus: true,
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  mounted() {
    this.getOpenAreaction();
    // 对民族数据格式进行转换
    this.nationList = this.nationDict.map((v) => {
      return {
        label: v,
        value: v,
      };
    });
    console.log(this.$route.query);
    Object.assign(this.form, this.$route.query);
    console.log(this.form);
  },
  methods: {
    back() {
      this.$router.back();
    },
    // 上传图片/视频
    // chargedMaterialOnSuccess(response, file, fileList) {
    //   console.log("chargedMaterialOnSuccess", response, file, fileList);
    //   console.log("fileList :>> ", fileList);
    //   this.form.chargedMaterial = fileList.map((item, index) => {
    //     return item.response.data;
    //   }, this);
    //   console.log("原来上传图片的数组列表", this.form.chargedMaterial);
    // },
    chargedMaterialOnRemove(file, fileList) {
      this.form.chargedMaterial = fileList.map((item, index) => {
        return item.response.data;
      }, this);
      console.log(this.form.chargedMaterial);
    },
    submitForm() {
      //   if (this.isEmpty(this.form.description)) {
      //   this.$elementMessage({
      //     showClose: false,
      //     message: "请填写您需要咨询的内容",
      //     type: "error",
      //   });
      //   return;
      // }
      // 验证咨询内容
      if (this.form.description === "") {
        this.$elementMessage({
          showClose: false,
          message: "请填写您需要咨询的内容",
          type: "error",
        });
        return;
      }
      // 验证姓名
      if (this.form.userName === "" && this.$route.query.serviceName != "公益申诉") {
        this.$elementMessage({
          showClose: false,
          message: "请填写您的姓名",
          type: "error",
        });
        return;
      }
      // 验证提供人姓名
      if (this.providePeople === "" && this.$route.query.serviceName == "公益申诉") {
        this.$elementMessage({
          showClose: false,
          message: "请填写线索提供人姓名",
          type: "error",
        });
        return;
      }
      // 验证民族
      if (
        this.nationValue === "" &&
        this.$route.query.serviceName == "法律援助"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请选择您的民族",
          type: "error",
        });
        return;
      }

      // 验证电话号码
      if (this.form.phone === "") {
        this.$elementMessage({
          showClose: false,
          message: "请填写您的联系电话",
          type: "error",
        });
        return;
      }
      // 验证身份证号
      if (this.form.infoCard === "") {
        this.$elementMessage({
          showClose: false,
          message: "请填写您的身份证号",
          type: "error",
        });
        return;
      }
      // 案件类别
      if (this.category === "" && this.$route.query.serviceName == "公益申诉") {
        this.$elementMessage({
          showClose: false,
          message: "请选择案件类别",
          type: "error",
        });
        return;
      }
      // 验证损害领域
      if (
        this.shiyValue === "" &&
        this.$route.query.serviceName == "公益申诉"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请选择损害领域",
          type: "error",
        });
        return;
      }
      // 验证所属地区
      if (this.address === "") {
        if (this.$route.query.serviceName == "公益申诉") {
          this.$elementMessage({
            showClose: false,
            message: "请选择损害区域",
            type: "error",
          });
        } else {
          this.$elementMessage({
            showClose: false,
            message: "请选择所属地区",
            type: "error",
          });
        }
        return;
      }
      // 验证街道
      if (this.street === "") {
        this.$elementMessage({
          showClose: false,
          message: "请选择街道/乡镇",
          type: "error",
        });
        return;
      }
      // 验证代理人信息
      // 代理人姓名
      if (
        this.agentObj.agentsName === "" &&
        this.$route.query.serviceName == "法律援助"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请填写代理人姓名",
          type: "error",
        });
        return;
      }
      // 身份证号
      if (
        this.agentObj.agentsIdCard === "" &&
        this.$route.query.serviceName == "法律援助"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请填写代理人身份证号",
          type: "error",
        });
        return;
      }
      // 联系电话
      if (
        this.agentObj.agentsMobile === "" &&
        this.$route.query.serviceName == "法律援助"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请填写代理人联系电话",
          type: "error",
        });
        return;
      }
      // 代理关系
      if (
        this.agentObj.agentsContact === "" &&
        this.$route.query.serviceName == "法律援助"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请选择代理关系",
          type: "error",
        });
        return;
      }
      // 验证问题类型
      if (
        this.legalValue === "" &&
        this.$route.query.serviceName == "法律咨询"
      ) {
        this.$elementMessage({
          showClose: false,
          message: "请选择问题类型",
          type: "error",
        });
        return;
      }
      let params = {
        consultMessage: {
          areaId: "5f574915b191a62dc8701b7f",
          departmentId: this.form.deptId,
          departmentName: this.form.deptName,
          imageList: this.form.chargedMaterial,
          serviceId: this.form.serviceId,
          serviceName: this.form.serviceName,
          demand: this.form.description,
          contactNumber: this.form.phone,
          cityCode: this.cityCode,
          idCard: this.form.infoCard, // 身份证号
          userName: this.form.userName, // 姓名
          sex: this.sex, // 性别
          ethnic: this.nationValue, // 民族
          provinces: this.provinces, // 省份
          city: this.city, // 城市
          county: this.county, // 区县
          villagesAndTowns: this.villagesAndTowns, // 乡镇
          village: this.village, // 村
          typeOfProblem: this.legalValue, // 问题类型
          openStatus: Number(this.openStatus), // 是否公开
          // 代理人
          agentsName: this.agentObj.agentsName,
          agentsIdCard: this.agentObj.agentsIdCard,
          agentsMobile: this.agentObj.agentsMobile,
          agentsContact: this.agentObj.agentsContact,

          // 公益诉讼
          providePeople: "", // 提供人姓名
          caseType: this.category, // 案件类别
          damageField: this.shiyValue, // 损害区域
          /**损害区域：省份*/
          damageProvinces: this.damageProvinces,
          /**损害区域：城市*/
          damageCity: this.damageCity,
          /**损害区域：区县*/
          damageCounty: this.damageCounty,
          /**损害区域：乡镇**/
          damageVillagesAndTowns: this.damageVillagesAndTowns,
          /**损害区域：村*/
          damageVillage: this.damageVillage,
          /**详细地址*/
          address: "",
        },
      };
      const loading = this.$loading.service({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$HTTP
        .httpToken({
          url: "/shouwei/service/consult",
          data: params,
          method: "post",
        })
        .then((res) => {
          loading.close();
          if (res.code == 0) {
            this.$elementMessage({
              showClose: false,
              message: "提交成功，请前往App端查看详情",
              type: "success",
            });
            this.$router.back();
          } else {
            this.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.close();
          this.$elementMessage({
            showClose: false,
            message: err,
            type: "error",
          });
        });
    },

    // 获取地区
    // 省市区选择
    handleChange(value) {
      console.log("省市区value :>> ", value);
      // console.log("label :>> ", label);
      this.address = value;
      console.log("this.address :>> ", this.address);
      const checkedNodes = this.$refs["deptList"].getCheckedNodes();
      // 打印当前点击省市区label数组
      console.log("节点", checkedNodes[0].pathLabels);

      let cityArr = checkedNodes[0].pathLabels;

      if (this.$route.query.serviceName == "公益申诉") {
        this.damageProvinces = cityArr[0];
        this.damageCity = cityArr[1];
        this.damageCounty = cityArr[2];
      } else {
        this.provinces = cityArr[0]; // 省
        this.city = cityArr[1]; // 城市
        this.county = cityArr[2]; // 区
      }

      this.streets = [];
      this.street = "";
      this.uploadDepts = [];
      this.streetSelect = [];
      this.upReportdepetValue = "";
      this.chaosongReportdepetValue = [];
      if (value.length == 3) {
        this.cityCode = value[2];
        this.getStreetAction();
      }
      // console.log("label :>> ", label);
      console.log("城市code");
      console.log(this.cityCode);
      this.getUploadDeptAction();
    },
    // 街道选择
    handleStreetChange(value) {
      console.log("街道value :>> ", value);
      this.street = value;
      console.log("this.street :>> ", this.street);
      // 乡镇
      this.villagesAndTowns = value[0];
      // 村
      this.village = value[1];
      this.streetSelect = value;
      this.country = value[0];
      this.town = value[1];
      console.log("街道", value);
      this.uploadDepts = [];
      this.upReportdepetValue = "";
      this.chaosongReportdepetValue = [];
      this.getUploadDeptAction();
    },
    //获取开通省市区
    getOpenAreaction() {
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getAreaOpenStatusDataList",
          data: {},
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            this.items = res.data.map(function (item, index) {
              var provice = {};
              provice["label"] = item.name;
              provice["value"] = item.cityCode;
              provice["children"] = item.children.map(function (
                city_item,
                index
              ) {
                var city = {};
                if (item.name === "北京市") {
                  city["value"] = 32678432;
                } else {
                  city["value"] = city_item.cityCode;
                }
                city["label"] = city_item.name;
                console.log(city_item.name);

                city["children"] = city_item.children.map(function (
                  area_item,
                  index
                ) {
                  var area = {};
                  area["label"] = area_item.name;
                  area["value"] = area_item.cityCode;
                  return area;
                });
                return city;
              });
              return provice;
            }, this);
            console.log("哥哥粉");
            console.log("items", this.items);
          } else {
            _self.$elementMessage.error("获取信息失败");
          }
        })
        .catch((err) => {
          _self.sendCodeLoading = false;
        });
    },
    getStreetAction() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getCountryAndTownsDataListByAreaCode",
          data: { areaCode: this.cityCode },
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            if (res.data.length != 0) {
              let datas = res.data[0].childrenList;
              console.log('datas :>> ', datas);
              this.streets = datas.map(function (item, index) {
                let provice = {};
                provice["label"] = item.townName;
                provice["value"] = item.townName;
                // provice["children"] = item.childrenList.map(function (
                //   city_item,
                //   index
                // ) {
                //   let city = {};
                //   city["value"] = city_item.countryName;
                //   city["label"] = city_item.countryName;
                //   return city;
                // });
                // if (provice["children"].length == 0) {
                //   let city = {};
                //   city["value"] = "";
                //   city["label"] = "直辖";
                //   provice["children"] = [city];
                // }
                return provice;
              }, this);
            }
          }
        })
        .catch((error) => { });
    },
    getUploadDeptAction() {
      var town = "";
      var country = "";
      if (this.streetSelect.length != 0) {
        town = this.streetSelect[0];
        if (town == "直辖") {
          town = "";
        }
        if (this.streetSelect.length > 1) {
          country = this.streetSelect[1];
          if (country == "直辖") {
            country = "";
          }
        }
      }
      let data = {
        cityCode: this.cityCode,
        townsName: town,
        countryName: country,
      };
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/department/getListByCityCode",
          data: data,
          method: "post",
        })
        .then((res) => {
          this.requestUploading = false;
          if (res.code == 0) {
            console.log("cityCode", res.data);
            this.uploadDepts = res.data;
            this.value = [];
            this.options = [];
            if (this.uploadDepts.length > 0) {
              this.uploadDept = this.uploadDepts[0];
              this.upReportdepetName = this.uploadDept.name;
              this.upReportdepetValue = this.uploadDept.id;

              let policesList = this.uploadDepts.map((v) => {
                return {
                  value: v.name,
                  label: v.name,
                  id: v.id,
                };
              });
              this.options = policesList;
              this.value = this.options[0].value;
              this.policesDeptId = this.options[0].id;
              console.log("this.options", this.options);
              console.log("this.value", this.value);
            }
          }
        })
        .catch((error) => { });
    },
    // 点击关闭弹框
    // handleClose(done) {
    //   // this.dialog = false;
    //   this.count = 0;
    // },
    // 案件类别值选择
    handleCategory(value) {
      console.log("案件类别", value);
      this.category = value[0];
    },
    // 损害领域
    handleShlyValue(value) {
      console.log("损害领域", value);
      this.shiyValue = value[0];
    },
    // 法律咨询 问题类型
    handleLegalValue(value) {
      this.legalValue = value[0];
    },
    // 选择性别
    handleSex(value) {
      this.sex = value;
    },
    // 选择民族
    handleNationValue(value) {
      this.nationValue = value[0];
    },
    // 选择代理关系
    handleAgentValue(value) {
      this.agentObj.agentsContact = value[0];
      console.log(
        "this.agentObj.agentsContact :>> ",
        this.agentObj.agentsContact
      );
    },
    // 是否公开
    isOpen(value) {
      console.log("value :>> ", value);
      // if (value === true) {
      //   this.openStatus = 1;
      // } else {
      //   this.openStatus = 0;
      // }
      console.log("this.openStatus :>> ", this.openStatus);
    },
    // 上传图片/视频方法占位
    // el-upload方法占位
    beforeuploadMainGraph(file) {
      console.log(file);
      let _this = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let newUrl = reader.result; //图片路径
        console.log(newUrl); //这个就是base64格式的了
      };
    },
    //上传成功
    handleSuccessMainGraph(response, file, fileList) {
      console.log(file);
      this.fileList = fileList;
      console.log("this.fileList :>> ", this.fileList);

      // 将上传的文件地址赋值给接口参数
      this.form.chargedMaterial = this.fileList.map((item, index) => {
        return item.response.data[0];
      }, this);

      console.log("现在上传的图片视频数组列表", this.form.chargedMaterial);

      this.imageUrl = response.data[0];
      if (this.imageUrl && this.imageUrl.indexOf(".mp4") != -1) {
        let path =
          "https://fileprod.skillhub.top/file/getVideoFileCoverBySizeV3?videoFileUrl=" +
          this.imageUrl;
        console.log("视频地址:" + path);
        this.videoPath = this.imageUrl;
        this.imageUrl = path;
        file.url = this.imageUrl;
        console.log("最后", this.imageUrl);
      }
    },
    //点击文件列表中已上传的文件
    handlePreviewMainGraph(file) {
      console.log("111", file);
      if (file.response.data[0].indexOf(".mp4") != -1) {
        this.dialogVisibleMainGraphVideo = true;
      } else {
        this.imageUrl = file.url;
        this.dialogVisibleMainGraph = true;
      }
    },
    //删除文件前
    beforeremoveMainGraph(file, fileList) { },
    //删除文件时
    handleRemoveMainGraph(file, fileList) {
      this.imageUrlList = fileList;
    },
  },
};
</script>

<style scoped>
.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.ant-input:focus {
  border: none;
  box-shadow: none;
}
</style>
